import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Learnbasicbreadcreumb from "../components/learn/learn-basic-breadcumb";

import Basiccontentpage from "../components/learn/basic-content";

// markup
const LearnbasicPage = () => {
  return (
    <div>
      <Seo title="Learn The Basics Of Meditation" />

      <Layout isSticky>
        <section>
          <Learnbasicbreadcreumb />
        </section>
        <section>
          <Basiccontentpage />
        </section>
      </Layout>
    </div>
  );
};

export default LearnbasicPage;
