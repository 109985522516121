import React from "react";

import relaxbeing from "../../assets/images/learn/relax-basic.jpg";

import rejuvenatebasic from "../../assets/images/learn/rejuvenate-basic.jpg";

import connectbasic from "../../assets/images/learn/connect-basic.jpg";

import { Link } from "gatsby";

export default function Basiccontentpage() {
  return (
    <section className="basiccontent-wrapper pad60">
      <div className="container">
        <div class="row">
          <div class="col-md-6 padd-0">
            <div class="cat-block-col">
              <h2 class="text-right block-heading time-title theme-color">
                Relax.
              </h2>
              <div class="cat-block-intro">
                <p class="text-right padd-tb5 theme-p-tag">
                  <strong>Expand consciousness</strong>
                </p>
                <p class="text-right theme-p-tag">
                  Learn Heartfulness Relaxation for physical-mental coherence
                  and calm, followed by Heartfulness Meditation on the source of
                  light in your heart to take you deeper within.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 padd-0">
            <div class="cat-img-col">
              <Link
                to="https://youtu.be/-bGeWAKKckc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img-fluid" src={relaxbeing} alt="Relax being" />
              </Link>
            </div>
          </div>
        </div>
        <div class="row block-order">
          <div class="col-md-6 padd-0">
            <div class="cat-img-col">
              <Link
                to="https://youtu.be/b_6-tX9U9xU"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-fluid"
                  src={rejuvenatebasic}
                  alt="Relax being"
                />
              </Link>
            </div>
            <div class="cat-sub-col">
              <div class="">
                <p class="text-center"></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 padd-0">
            <div class="cat-block-col">
              <h2 class="text-left block-heading time-title theme-color">
                Rejuvenate.
              </h2>
              <div class="cat-block-intro ">
                <p class="text-leftpadd-tb5 theme-p-tag black-color">
                  <strong>Simplify</strong>
                </p>
                <p class="theme-p-tag text-left">
                  Daaji will guide you through a simple rejuvenating method to
                  detox your mind. It is useful at the end of the day to let go
                  of stress and complex emotions and will leave you feeling
                  light and refreshed.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 padd-0">
            <div class="cat-block-col hfn-community-block">
              <h2 class="text-right block-heading time-title theme-color">
                Connect.
              </h2>
              <div class="cat-block-intro">
                <p class="text-right padd-tb5 theme-p-tag black-color">
                  <strong>With your inner self</strong>
                </p>
                <p class="theme-p-tag text-right padd-tb5">
                  Learn to connect with your inner self by listening to the
                  heart’s voice. Observe your deepest feelings, make wise
                  choices and weave your destiny.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 padd-0">
            <div class="cat-img-col ">
              <Link
                to="https://youtu.be/LE_AI1Vb4C0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-fluid"
                  src={connectbasic}
                  alt="Connect Basic"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
